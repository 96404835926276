import React from 'react'
import Title from './Title'
import Image from 'gatsby-image'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
//...GatsbyImageSharpFluid

const Instagram = () => {
  // const data = useStaticQuery(query)
  // const {
  //   allInstaNode: { nodes },
  // } = data
  // return (
  //   <Wrapper>
  //     <Title title="Instagram" />
  //     <div className="images">
  //       {nodes.map((item, index) => {
  //         const {
  //           localFile: {
  //             childImageSharp: { fluid },
  //           },
  //         } = item
  //         return <Image key={index} fluid={fluid} />
  //       })}
  //     </div>
  //   </Wrapper>
  // )
}

// export const query = graphql`
//   {
//     allInstaNode(limit: 6) {
//       nodes {
//         localFile {
//           childImageSharp {
//             fluid {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//     }
//   }
// `

const Wrapper = styled.article`
  .images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
`

export default Instagram
