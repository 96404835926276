import React from 'react'
import SocialLinks from '../../constants/socialLinks'
import Image from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import Title from './Title'
import styled from 'styled-components'
//...GatsbyImageSharpFixed
const query = graphql`
  {
    file(relativePath: { eq: "banner-about.jpeg" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
      relativePath
    }
  }
`
const About = () => {
  const data = useStaticQuery(query)
  const {
    file: {
      childImageSharp: { fixed },
    },
  } = data
  return (
    <Wrapper>
      <Title title="About me" />
      <Image fixed={fixed} className="img" />
      <p>
        At primis elementum vivamus penatibus dictum malesuada litora proin
        posuere
      </p>
      <SocialLinks styleClass="banner-icons" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  p {
    color: var(--clr-grey-5);
  }
  .img {
    border-radius: 50%;
  }
`
export default About
