import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import Title from './Title'
// ...GatsbyImageSharpFluid

const Recent = () => {
  const data = useStaticQuery(query)
  const {
    allMdx: { nodes: posts },
  } = data
  return (
    <Wrapper>
      <Title title="Recent post" />
      <div className="posts">
        {posts.map(post => {
          const {
            frontmatter: {
              date,
              slug,
              image: {
                childImageSharp: { fluid },
              },
              title,
            },
            id,
          } = post
          return (
            <Link key={id} className="post" to={`/posts/${slug}`}>
              <Image fluid={fluid} className="img" />
              <div>
                <h5>{title}</h5>
                <p>{date}</p>
              </div>
            </Link>
          )
        })}
      </div>
    </Wrapper>
  )
}

export const query = graphql`
  {
    allMdx(limit: 5, sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM, Do YYYY")
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          slug
          title
        }
        id
      }
    }
  }
`

const Wrapper = styled.div`
  .post {
    display: grid;
    grid-template-columns: 75px 1fr;
    column-gap: 1rem;
    margin-bottom: 1rem;
  }
  .img {
    border-radius: var(--radius);
  }
  h5 {
    font-size: 0.7rem;
    margin-bottom: 0.25rem;
    letter-spacing: 0;
    line-height: 1.2;
    color: var(--clr-grey-1);
  }
  p {
    font-size: 0.6rem;
    margin-bottom: 0;
    color: var(--clr-grey-5);
  }
  .post:hover {
    h5 {
      color: var(--clr-primary-5);
    }
  }
`

export default Recent
